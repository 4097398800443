<template>
  <div>
    <transition name="fade">
      <div class="fixed" v-if="showLoad">
        <div class="loader">
          <span>E</span>
          <span>A</span>
          <span>S</span>
          <span>T</span>
          <span>L</span>
          <span>I</span>
          <span>N</span>
          <span>E</span>

          <div class="covers">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: [
    'myShow'
  ],
  computed: {
    showLoad(){
      let myShow = Object.values(this.myShow);
      // myShow = myShow.flat();
      console.log(this.myShow);
      return this.myShow;
    }
  },
}
</script>

<style scoped>

  @import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);

  * {
  box-sizing: border-box;
  overflow: hidden;
  }

  body {
  padding-top: 10em;
  text-align: center;
  }

  .fixed{
    transition: all .8s ;
    background: #eee;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 2000;
    justify-content: center;
    align-self: center;
  }
  .loader {
  position: relative;
  margin: auto;
  width: 400px;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 250%;
  background: linear-gradient(180deg, #222 0, #444 100%);
  box-shadow: inset 0 5px 20px black;
  text-shadow: 5px 5px 5px rgba(0,0,0,0.3);
  }

  .loader:after {
  content: "";
  display: table;
  clear: both;
  }

  span {
  float: left;
  height: 100px;
  line-height: 120px;
  width: 50px;
  text-align: center;
  background: green;
  }

  .loader > span {
  border-left: 1px solid #006000;
  border-right: 1px solid #007000;
  }

  .covers {
  position: absolute;
  height: 100%;
  width: 100%;
  }

  .covers span {
  background: linear-gradient(180deg, white 0, #ddd 100%);
  animation: up 2s infinite;
  }

  @keyframes up {
  0%   { margin-bottom: 0; }
  16%  { margin-bottom: 100%; height: 20px; }
  50% { margin-bottom: 0; }
  100% { margin-bottom: 0; }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0
  }

  .covers span:nth-child(2) { animation-delay: .142857s; }
  .covers span:nth-child(3) { animation-delay: .285714s; }
  .covers span:nth-child(4) { animation-delay: .428571s; }
  .covers span:nth-child(5) { animation-delay: .571428s; }
  .covers span:nth-child(6) { animation-delay: .714285s; }
  .covers span:nth-child(7) { animation-delay: .857142s; }
  .covers span:nth-child(8) { animation-delay: .999999s; }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }

</style>
