import Vue from 'vue'
import Router from 'vue-router'
import store from '../vuex/store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Login
const Login = () => import('@/views/pages/Login')

// Internatioanl countries
const InternatioanlCountry = () => import('@/views/international/countries/Countries')
const InternatioanlRegion = () => import('@/views/international/regions/Regions')
const InternatioanlCity = () => import('@/views/international/cities/Cities')
const InternatioanlBranch = () => import('@/views/international/branches/Branches')

// Local regions
const LocalRegion = () => import('@/views/local/regions/Regions')
const LocalCity = () => import('@/views/local/cities/Cities')
const LocalBranch = () => import('@/views/local/branches/Branches')

// Users
const Users = () => import('@/views/users/Users')
const UserByRole = () => import('@/views/users/UserByRole')
const TrackingDriver = () => import('@/views/users/TrackingDriver')

// Clients
const Clients = () => import('@/views/clients/Clients')
const Addresses = () => import('@/views/clients/Addresses')
const Contracts = () => import('@/views/clients/Contracts')
const ContractTemplates = () => import('@/views/clients/ContractTemplates')

// Details
const Statuses = () => import('@/views/details/Statuses')
const TypeOfCargo = () => import('@/views/details/TypeOfCargo')
const TypeOfService = () => import('@/views/details/TypeOfService')

// Tariffs
const TariffCreate = () => import('@/views/tariffs/Create')
const TariffEdit = () => import('@/views/tariffs/Edit')
const Tariffs = () => import('@/views/tariffs/Tariffs')

// PromoCodes
const PromoCodes = () => import('@/views/promocodes/PromoCodes')

// Заказы
const InternationalOrders = () => import('@/views/orders/International')
const LocalOrders = () => import('@/views/orders/Local')

// Mailing
const EmailGroups = () => import('@/views/mailing/email/EmailGroups')
const EmailAbonents = () => import('@/views/mailing/email/EmailAbonents')
const EmailMailing = () => import('@/views/mailing/email/EmailMailing')
const EmailMailingAbonentsByListId = () => import('@/views/mailing/email/EmailMailingAbonentsByListId')

Vue.use(Router)
const checkAuth = (to, from, next) => {
  if(localStorage.auth){
    if (JSON.parse(localStorage.auth)) {
      next();
      return;
    }
  }
  console.log('unauth');
  next("/pages/login");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Главная',
      component: TheContainer,
      beforeEnter: checkAuth,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'orders-international',
          name: 'Международные заказы',
          component: InternationalOrders
        },
        {
          path: 'orders-local',
          name: 'Местные заказы',
          component: LocalOrders
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: 'tracking-drivers',
              name: 'Tracking drivers',
              component: TrackingDriver
            },
            {
              path: 'role/:role',
              meta: {
                label: 'User By Role'
              },
              name: 'UserByRole',
              component: UserByRole
            }
          ]
        },
        {
          path: '',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'tracking-drivers',
              name: 'Tracking drivers',
              component: TrackingDriver
            },
          ]
        },
        {
          path: 'clients',
          meta: {
            label: 'Клиенты'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Клиенты',
              component: Clients
            },
            {
              path: 'addresses',
              name: 'Адреса клиентов',
              component: Addresses
            },
            {
              path: 'contracts',
              name: 'Договора с клиентами',
              component: Contracts
            },
            {
              path: 'contracts/templates',
              name: 'Шаблоны договоров',
              component: ContractTemplates
            },
          ]
        },
        {
          path: 'promocodes',
          name: 'Промокоды',
          component: PromoCodes
        },
        {
          path: '/international',
          redirect: '/international/countries',
          name: 'Международные',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'countries',
              name: 'Страны',
              component: InternatioanlCountry
            },
            {
              path: 'regions',
              name: 'Области',
              component: InternatioanlRegion
            },
            {
              path: 'cities',
              name: 'Города',
              component: InternatioanlCity
            },
            {
              path: 'branches',
              name: 'Офисы',
              component: InternatioanlBranch
            },
          ]
        },
        {
          path: '/local',
          redirect: '/local/regions',
          name: 'Местные',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'regions',
              name: 'Области',
              component: LocalRegion
            },
            {
              path: 'districts',
              name: 'Районы',
              component: LocalCity
            },
            {
              path: 'branches',
              name: 'Офисы',
              component: LocalBranch
            },
          ]
        },
        {
          path: '/details',
          redirect: '/details/statuses',
          name: 'Детали',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'statuses',
              name: 'Статусы',
              component: Statuses
            },
            {
              path: 'type-of-cargo',
              name: 'Тип груза',
              component: TypeOfCargo
            },
            {
              path: 'type-of-service',
              name: 'Тип сервиса',
              component: TypeOfService
            }
          ]
        },
        {
          path: '/tariffs',
          redirect: '/tariffs',
          name: 'Тарифы',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Все тарифы',
              component: Tariffs
            },
            {
              path: 'create',
              name: 'Правила оформления тарифа',
              component: TariffCreate
            },
            {
              path: 'edit/:id',
              name: 'Изменение тарифа',
              component: TariffEdit
            }
          ]
        },
        {
          path: '/mailing',
          redirect: '/mailing/email-mailing',
          name: 'Email рассылка',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'email-groups',
              name: 'Группы',
              component: EmailGroups
            },
            {
              path: 'email-abonents',
              name: 'Абоненты',
              component: EmailAbonents
            },
            {
              path: 'email-mailing',
              name: 'Email рассылка',
              component: EmailMailing
            },
            {
              path: 'email-mailing-abonents-by-id/:id',
              name: 'Абоненты рассылки',
              component: EmailMailingAbonentsByListId
            }
          ]
        }
      ],

    },
    {
      path: '/pages',
      redirect: '/pages/login',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}
